import React from 'react';
import './Modal.css'; // Custom CSS file for modal styles
import { useSelector } from 'react-redux';

const Modal = ({ isOpen, onClose, children }) => {

  const isModalOpen = useSelector((state) => state.isModalOpen);

  if (!isModalOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
