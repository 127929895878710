import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css'; // You can add your custom CSS here
import { useSelector } from 'react-redux';

const AddItem = () => {

    const [formData, setFormData] = useState({
        itemId: '',
        itemName: '',
        category: '',
        supplier: '',
        reorderLevel: '',
        description: '',
        dateAdded: '',
        batchNumber: '',
        brand: '',
        image: '',
        tags: '',
        quantity: '',
        safetyStockLevel: ''
      });


    const token = useSelector(state => state.userTokenData);
    const userData = useSelector(state => state.userData);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateAdded: date
    });
  };



  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   for (const field in formData) {
  //     if (field !== 'image' && !formData[field]) {
  //       alert(`${field.replace(/([A-Z])/g, ' $1').trim()} is required`);
  //       return;
  //     }
  //   }

  //   const { image, ...dataToSubmit } = formData;

  //   console.log('Form data submitted:', dataToSubmit);
  //   // Add your form submission logic here


  //   console.log("Ok let take it off.");





  // };



  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const userId = (userData.id)?userData.id:null;
  
    // Check for required fields
    for (const field in formData) {
      if (field !== 'image' && !formData[field]) {
        alert(`${field.replace(/([A-Z])/g, ' $1').trim()} is required`);
        return;
      }
    }
  
    const { image, ...dataToSubmit } = formData;
  
    // Prepare the form data for submission
    const formDataToSend = {
      ...dataToSubmit,
      created_by: userId, // Replace userId with the actual user ID from your state or context
      time: new Date().toISOString(), // Current timestamp
    };
  
    try {

      const response = await fetch('http://localhost:8787/inventory', {
        // const response = await fetch('https://powerbox-inventory.bitprimesolutions.workers.dev/inventory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token if you are using authentication
        },
        body: JSON.stringify(formDataToSend)
      });
  
      if (response.ok) {
        console.log('Inventory data uploaded successfully');
        // Handle successful submission (e.g., redirect, reset form, etc.)
      } else {
        const errorData = await response.json();
        console.error('Error uploading data:', errorData);
        alert('There was an error uploading the data');
      }
    } catch (error) {
      console.error('Error during submission:', error);
      alert('An unexpected error occurred');
    }
  
    console.log("Ok let's take it off.");
  };
  




  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
  };

  return (
    <div className="App">
       
       <div style={{display:'flex',marginTop:40,paddingBottom:20}}>
            <h1>Add New Item To Inventory</h1>
        </div>


      <form onSubmit={handleSubmit} className="inventory-form">
        
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Object.keys(formData).map((field) => (
            <div key={field} className="form-group">
              <label htmlFor={field}>{field.replace(/([A-Z])/g, ' $1').trim()}</label>
              {field === 'category' ? (
                <select
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Category</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Clothing">Clothing</option>
                  <option value="Food">Food</option>
                  <option value="Stationery">Stationery</option>
                  {/* Add more categories as needed */}
                </select>
              ) : field === 'dateAdded' ? (
                <DatePicker
                  selected={formData[field]}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  required
                />
              ) : (
                <input
                  type={
                    field === 'quantity' ||
                    field.includes('Price') ||
                    field.includes('Rate') ||
                    field.includes('Level') ||
                    field.includes('Time')
                      ? 'number'
                      : 'text'
                  }
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  required
                />
              )}
            </div>
          ))}
        </Masonry>
        
        <div style={{display:'flex',paddingTop:20,paddingBottom:20,paddingLeft:20}}>
            <button type="submit" className="submit-button">Add Item</button>
        </div>

      </form>
    </div>
  );
};

export default AddItem;