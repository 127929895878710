import React, { useState,useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css'; 

const ProcurementForm = () => {
  const [formData, setFormData] = useState({
    itemName: '',
    itemId: '',
    quantity: '',
    unitPrice: '',
    supplierName: '',
    supplierContact: '',
    deliveryDate: null,
    localDeliveryDate: null,
    trackingNumber: '',
    orderStatus: 'Pending',
    currency:''
  });

  const [token, setToken] = useState(null);


  useEffect(() => {
    // Assume you have a function to get the token after user logs in
    const token = localStorage.getItem('authToken');
    
    setToken(token);

  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
   

    setFormData({
      ...formData,
      [name]: date
    });

  };





  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all fields are filled
    const isFormValid = Object.values(formData).every(field => field !== '' && field !== null);

    if (!isFormValid) {
      alert('Please fill out all fields.');
      return;
    }

   

    try {
      const response = await fetch('https://powerbox-inventory.bitprimesolutions.workers.dev/procurement', {
        // const response = await fetch('http://localhost:8787/procurement', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Procurement data added successfully!');
        // setFormData({
        //   itemName: '',
        //   itemId: '',
        //   quantity: 0,
        //   unitPrice: 0,
        //   supplierName: '',
        //   supplierContact: '',
        //   deliveryDate: '',
        //   localDeliveryDate: '',
        //   trackingNumber: '',
        //   orderStatus: '',
        //   createdOn: '',
        //   currency: '',
        // });
      } else {
        
        console.log(result);

        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error('Error submitting procurement data:', error);
      alert('Error submitting procurement data');
    }






  };










  return (
    <div style={{ padding: '20px', paddingBottom: '150px', marginTop: 100 }}>
      <div style={{ padding: 15, fontSize: 25, color: '#060713', paddingBottom: 50 }}>
        Add New Procurement.
      </div>

      <form onSubmit={handleSubmit} className="procurement-form">
        <div className="form-group">
          <label htmlFor="itemName">Item Name:</label>
          <input
            type="text"
            id="itemName"
            name="itemName"
            value={formData.itemName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="itemId">Item ID:</label>
          <input
            type="text"
            id="itemId"
            name="itemId"
            value={formData.itemId}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="quantity">Quantity:</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="unitPrice">Unit Price:</label>
          <input
            type="number"
            id="unitPrice"
            name="unitPrice"
            value={formData.unitPrice}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="supplierName">Supplier Name:</label>
          <input
            type="text"
            id="supplierName"
            name="supplierName"
            value={formData.supplierName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="supplierContact">Supplier Contact:</label>
          <input
            type="text"
            id="supplierContact"
            name="supplierContact"
            value={formData.supplierContact}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="deliveryDate">Delivery Date:</label>
          <DatePicker
            id="deliveryDate"
            name="deliveryDate"
            selected={formData.deliveryDate}
            onChange={(date) => handleDateChange('deliveryDate', date)}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select a date"
          />
        </div>
        <div className="form-group">
          <label htmlFor="localDeliveryDate">Local Delivery Date:</label>
          <DatePicker
            id="localDeliveryDate"
            name="localDeliveryDate"
            selected={formData.localDeliveryDate}
            onChange={(date) => handleDateChange('localDeliveryDate', date)}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select a date"
          />
        </div>
        <div className="form-group">
          <label htmlFor="trackingNumber">Tracking Number:</label>
          <input
            type="text"
            id="trackingNumber"
            name="trackingNumber"
            value={formData.trackingNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="orderStatus">Order Status:</label>
          <select
            id="orderStatus"
            name="orderStatus"
            value={formData.orderStatus}
            onChange={handleChange}
          >
            <option value="awaiting_payment">Awaiting Payment</option>
            <option value="awaiting_local_shipment">Awaiting Local Shipment</option>
            <option value="awaiting_international_shipment">Awaiting International Shipment</option>
            <option value="shipped_international">Shipped International</option>
            <option value="shipped_local">Shipped Local</option>
            <option value="collected">Collected</option>
          </select>
        </div>


        <div className="form-group">
          <label htmlFor="orderStatus">Currency:</label>
          <select
            id="currency"
            name="currency"
            value={formData.currency}
            onChange={handleChange}
          >
            <option value="NGN">NGN</option>
            <option value="RMB">RMB</option>
            <option value="USD">USD</option>
            <option value="GBP">GBP</option>
          </select>
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ProcurementForm;
