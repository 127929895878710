import React, { useEffect, useState } from 'react';
import './style.css'; 
import Modal from'../modal';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../actions';
import DateFormatter from '../../containers/dateFormatter';
import DatePicker from 'react-datepicker';



// import AppStyles from '../../lib/style_config';
// import CustomTextInput from '../defaults/textinput';

// import { useHistory } from "react-router-dom";

// import MasonryLayout from '../../lib/masory_layout';
import Masonry from 'react-masonry-css';







const fetchDataWithToken = async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('authToken');
  
      // Check if the token is present
      if (!token) {
        throw new Error('No token found');
      }
  
      // Prepare the headers with the Authorization Bearer token
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };
  
      // Make the API request
      const response = await fetch('https://powerbox-inventory.bitprimesolutions.workers.dev/procurement', {
        method: 'GET', // or 'POST' depending on your API
        headers: headers,
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Parse the JSON data from the response
      const data = await response.json();
  
      // Return the data
      console.log(data.data);
      return data.data;
  
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; // or you can handle the error more gracefully
    }
  };







export function EachDashboardLiItem({ item }) {

    const dispatch = useDispatch();

    const triggerModal = () =>{
        dispatch(ActionCreators.setProcureModalData(item));
        dispatch(ActionCreators.toggleModal(true));
    }


    return (
        <div onClick={triggerModal} href="" className="masonry-item">
            <div style={{ fontWeight: 'bold',color:'#3F559F',paddingBottom:10 }}>{item.itemName}</div>

            <div style={{fontSize:14,paddingBottom:5}}>Quantity: {item.quantity}</div>
            <div style={{fontSize:14,paddingBottom:5}}>Unit price: {item.unitPrice}</div>
            <div style={{fontSize:14,paddingBottom:15}}>Order Currency: {item.currency}</div>

            <div style={{fontSize:14,paddingBottom:5}}>Created on: <DateFormatter isoDate={item.createdOn} /> </div>
            <div style={{fontSize:14,paddingBottom:5}}>Delivery Date: <DateFormatter isoDate={item.deliveryDate} /> </div>
            <div style={{fontSize:14,paddingBottom:15}}>Nig Delivery Date: <DateFormatter isoDate={item.localDeliveryDate} /> </div>
            
            <div style={{fontSize:11,paddingBottom:5}}>Platform Of Purchase: {item.supplierContact}</div>
            <div style={{fontSize:11,paddingBottom:5}}>Supplier Name: {item.supplierName}</div>
            <div style={{fontSize:11,paddingBottom:15}}>Tracking Number: {item.trackingNumber}</div>

            <div style={{fontSize:14,paddingBottom:15}}>Status: <span style={{color:'#F75853'}}>{item.orderStatus}</span></div>
            <div style={{fontSize:14,paddingBottom:5}}>Notes: {item.itemId}</div>

        </div>
    );
}




export function InventoryLoop() {


    const [token, setToken] = useState(null);
    const [dataIn, setDataIn] = useState([]);
    const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchDataWithToken();
      setData(result);
    };
    fetchData();
  }, []);

   
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className="masonry-container">
            <div className="masonry-header">Procurements</div>
            {data ? (
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
            >
                {data.map((item, index) => (
                    <EachDashboardLiItem key={index} item={item} />
                ))}       
            </Masonry>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}


export function UpateItem(){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        itemName: '',
        itemId: '',
        quantity: '',
        unitPrice: '',
        supplierName: '',
        supplierContact: '',
        deliveryDate: null,
        localDeliveryDate: null,
        trackingNumber: '',
        orderStatus: 'Pending',
        currency:''
      });

      const [message, setMessage] = useState('');
  
    // const openModal = () => setIsModalOpen(true);
    const closeModal = () => dispatch(ActionCreators.toggleModal(false));
    const modalData = useSelector(state => state.modalData);

    const procurementModalData = useSelector(state => state.procurementModalData);

    const token = useSelector(state => state.userTokenData);


    const inputStyle = {
        width: '100%', 
        flex:'0.7',         // Set the width to 100%
        padding: '10px',        // Add padding inside the input
        margin: '0px',       // Add margin above and below the input
        fontSize: '16px',       // Set font size
        borderRadius: '5px',    // Make the borders rounded
        border: '1px solid #ccc', // Light grey border
        boxSizing: 'border-box' // Include padding in width and height calculations
      };


      const handleDateChange = (name, date) => {
   

        setFormData({
          ...formData,
          [name]: date
        });
    
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      useEffect(()=>{

        console.log("new data is into the modal");

        // update Update NIgerian Delivery Date on entery

        setFormData({
            ...formData,
            localDeliveryDate: procurementModalData.localDeliveryDate
          });


           // update Update tracking number on entery

        setFormData({
            ...formData,
            trackingNumber: procurementModalData.trackingNumber
          });


          // update Update status on entery

        setFormData({
            ...formData,
            orderStatus: procurementModalData.orderStatus
          });


           // update Update status on entery

        setFormData({
            ...formData,
            itemId: procurementModalData.itemId
          });


      },[procurementModalData]);



      const uploadDeliverDate = async (e) => {
        e.preventDefault();
        if (!token) {
          setMessage('No authentication token found');
          return;
        }
        const localDeliveryDate = formData.localDeliveryDate;
        const id = procurementModalData.id;
        try {
        //   const response = await fetch(`http://localhost:8787/procurement/update/${id}`, {
          const response = await fetch(`https://powerbox-inventory.bitprimesolutions.workers.dev/procurement/update/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the token in the request header
            },
            body: JSON.stringify({ localDeliveryDate }),
          });
          const data = await response.json();
          if (response.ok) {
            setMessage('Local delivery date updated successfully!');
          } else {
            console.log(data.error );
            setMessage(`Error: ${data.error || 'Failed to update local delivery date'}`);
          }
        } catch (error) {
          console.error('Error updating local delivery date:', error);
          setMessage('An error occurred while updating local delivery date.');
        }
      };


      const uploadTrackingNumber = async (e) => {
        e.preventDefault();
        if (!token) {
          setMessage('No authentication token found');
          return;
        }
        const trackingNumber = formData.trackingNumber;
        const id = procurementModalData.id;
        try {
        //   const response = await fetch(`http://localhost:8787/procurement/update_tracker/${id}`, {
          const response = await fetch(`https://powerbox-inventory.bitprimesolutions.workers.dev/procurement/update_tracker/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the token in the request header
            },
            body: JSON.stringify({ trackingNumber }),
          });
          const data = await response.json();
          if (response.ok) {
            setMessage('Tracking number updated successfully!');
          } else {
            console.log(data.error );
            setMessage(`Error: ${data.error || 'Failed to update Tracking number'}`);
          }
        } catch (error) {
          console.error('Error updating Tracking number:', error);
          setMessage('An error occurred while updating Tracking number.');
        }
      };


      

      const uploadOrderStatus = async (e) => {
        e.preventDefault();
        if (!token) {
          setMessage('No authentication token found');
          return;
        }
        const orderStatus = formData.orderStatus;
        const id = procurementModalData.id;
        try {
        //   const response = await fetch(`http://localhost:8787/procurement/update_tracker/${id}`, {
          const response = await fetch(`https://powerbox-inventory.bitprimesolutions.workers.dev/procurement/update_status/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the token in the request header
            },
            body: JSON.stringify({ orderStatus }),
          });
          const data = await response.json();
          if (response.ok) {
            setMessage('Status updated successfully!');
          } else {
            console.log(data.error );
            setMessage(`Error: ${data.error || 'Failed to update Status'}`);
          }
        } catch (error) {
          console.error('Error updating Status:', error);
          setMessage('An error occurred while updating Status.');
        }
      };



      const uploadItemId = async (e) => { 
        e.preventDefault();
        if (!token) {
          setMessage('No authentication token found');
          return;
        }
        const itemId = formData.itemId;
        const id = procurementModalData.id;
        try {
        //   const response = await fetch(`http://localhost:8787/procurement/update_tracker/${id}`, {
          const response = await fetch(`https://powerbox-inventory.bitprimesolutions.workers.dev/procurement/update_itemId/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the token in the request header
            },
            body: JSON.stringify({ itemId }),
          });

          const data = await response.json();
          if (response.ok) {
            setMessage('Notes updated successfully!');
          } else {
            console.log(data.error );
            setMessage(`Error: ${data.error || 'Failed to update Notes'}`);
          }
        } catch (error) {
          console.error('Error updating Notes:', error);
          setMessage('An error occurred while updating Notes.');
        }
      };


  
    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h3 style={{color:'#A33616'}}>Update {procurementModalData.itemName}</h3>
          <div>{message}</div>


          <div style={{display:'block'}}>
            
            <div style={{paddingBottom:30}}>
                <div style={{paddingBottom:5,fontSize:13}}>Update NIgerian Delivery Date</div>
                
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    
                    <div style={{}}>
                        <DatePicker
                            id="deliveryDate"
                            name="localDeliveryDate"
                            selected={formData.localDeliveryDate}
                            onChange={(date) => handleDateChange('localDeliveryDate', date)}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select a date"
                        />
                    </div>

                    <div style={{}}>
                        <div onClick={uploadDeliverDate} style={{backgroundColor:'#3F559F',color:'#FFFFFF',padding:'8px',paddingLeft:'20px',paddingRight:'20px',borderRadius:5,cursor:'pointer'}}>Update Date</div>
                    </div>
                </div>

            </div>



            <div style={{paddingBottom:30}}>
                <div style={{paddingBottom:5,fontSize:13}}>Update Tracking Number</div>
                
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    
                    <div style={{}}>
                        <input
                            type="text"
                            id="trackingNumber"
                            name="trackingNumber"
                            style={inputStyle}
                            value={formData.trackingNumber}
                            onChange={handleChange}
                        />
                    </div>

                    <div style={{}}>
                        <div onClick={uploadTrackingNumber} style={{backgroundColor:'#3F559F',color:'#FFFFFF',padding:'8px',paddingLeft:'20px',paddingRight:'20px',borderRadius:5,cursor:'pointer'}}>Update</div>
                    </div>
                </div>

            </div>



            <div style={{paddingBottom:30}}>
                <div style={{paddingBottom:5,fontSize:13}}>Update Status</div>
                
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    
                    <div style={{}}>
                        <select
                            id="orderStatus"
                            name="orderStatus"
                            value={formData.orderStatus}
                            onChange={handleChange}
                            style={inputStyle}
                        >
                            <option value="awaiting_payment">Awaiting Payment</option>
                            <option value="awaiting_local_shipment">Awaiting Local Shipment</option>
                            <option value="awaiting_international_shipment">Awaiting International Shipment</option>
                            <option value="shipped_international">Shipped International</option>
                            <option value="shipped_local">Shipped Local</option>
                            <option value="collected">Collected</option>
                        </select>
                    </div>

                    <div style={{}}>
                        <div onClick={uploadOrderStatus} style={{backgroundColor:'#3F559F',color:'#FFFFFF',padding:'8px',paddingLeft:'20px',paddingRight:'20px',borderRadius:5,cursor:'pointer'}}>Update Status</div>
                    </div>
                </div>

            </div>



            <div style={{paddingBottom:30}}>
                <div style={{paddingBottom:5,fontSize:13}}>Update Notes</div>
                
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    
                    <div style={{}}>
                        <input
                            type="text"
                            id="itemId"
                            name="itemId"
                            value={formData.itemId}
                            onChange={handleChange}
                            style={inputStyle}
                        />
                    </div>

                    <div style={{}}>
                        <div onClick={uploadItemId} style={{backgroundColor:'#3F559F',color:'#FFFFFF',padding:'8px',paddingLeft:'20px',paddingRight:'20px',borderRadius:5,cursor:'pointer'}}>Update Note</div>
                    </div>
                </div>

            </div>






          </div>
        </Modal>
    );
  }



export default function ProcurementListUI() {
    return (
        <div style={{ padding: '20px' }}>
            <InventoryLoop />
            <UpateItem/>
        </div>
    );
}
