import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { applyMiddleware,compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import ResponsiveWrapper from './app/containers/responsiveWrapper';

// import thunk from 'redux-thunk'
import { thunk } from 'redux-thunk';

import reducer from './app/reducers';
import logger from 'redux-logger';

import { Provider } from 'react-redux'




function configureAppStore(initialState) {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(thunk, logger),
    preloadedState: initialState,
  });
}


let storep = configureAppStore({});;




export const store = storep;



function App() {

  const [appReady,setAppReady] = useState(false);
  const [mounted, setMounted] = useState(false);


  return (
    <Provider store={store}>
        <div className="AppWrapper">
          <ResponsiveWrapper/>
        </div>
    </Provider>
  );
}

export default App;




// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
