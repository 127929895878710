import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

export const procurementModalData = createReducer({},{
    [types.SET_PROCURE_MODAL_DATA](state,action){
        return (action.data);
    }
  });


export const userData = createReducer({},{
    [types.SET_USER_DATA](state,action){
        return (action.data);
    }
  });


export const userTokenData = createReducer('',{
    [types.SET_USER_TOKEN_DATA](state,action){
        return (action.data);
    }
  });


