import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

// export const notificationText = createReducer("notificat",{
//     [types.SET_NOTIFICATION_TEXT](state,action){
//         return (action.stat);
//     }
//   });

export const isModalOpen = createReducer(false,{
    [types.SET_TOGGLE_MODAL](state,action){
        return (action.data);
    }
  });


