import React, { useEffect, useState } from 'react';
import './style.css'; 

// import { useDispatch, useSelector } from 'react-redux';
// import { ActionCreators } from '../../actions';
// import AppStyles from '../../lib/style_config';
// import CustomTextInput from '../defaults/textinput';
// import { useHistory } from "react-router-dom";
// import MasonryLayout from '../../lib/masory_layout';

import Masonry from 'react-masonry-css';

const data = [
    { itemName: 'Make New Procurement', link: '/procurement_manager/form' },
    { itemName: 'Manage Procurement',  link: '/procurement_manager/list' }
  ];

export function EachDashboardLiItem({ item }) {
    return (
        <a href={item.link} className="masonry-item">
            <div style={{ fontWeight: 'bold',color:'#3F559F',paddingBottom:10 }}>{item.itemName}</div>
            <div style={{fontSize:14}}>. {item.qty_remaining}</div>
        </a>
    );
}

export function InventoryLoop() {
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className="masonry-container">
            <div className="masonry-header">Procurement Manager</div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
            >
                {data.map((item, index) => (
                    <EachDashboardLiItem key={index} item={item} />
                ))}
            </Masonry>
        </div>
    );
}


export default function ProcurementUI() {
    return (
        <div style={{ padding: '20px' }}>
            <InventoryLoop />
        </div>
    );
}
