import React, { useEffect, useState } from 'react';
import './styles.css'; 
// import { useDispatch, useSelector } from 'react-redux';
// import { ActionCreators } from '../../actions';
// import AppStyles from '../../lib/style_config';
// import CustomTextInput from '../defaults/textinput';

// import { useHistory } from "react-router-dom";

// import MasonryLayout from '../../lib/masory_layout';
import Masonry from 'react-masonry-css';

const data = [
    { item_name: 'RF540', qty_remaining: '30', link: '/rf540' },
    { item_name: 'Inverters', qty_remaining: '30', link: '/inverters' },
    { item_name: 'Diode', qty_remaining: '200', link: '/diode' },
    { item_name: '1k Resistor', qty_remaining: '100', link: '/1k-resistor' },
    { item_name: '100k Resistor', qty_remaining: '1200', link: '/100k-resistor' },
    { item_name: 'Inverters', qty_remaining: '30', link: '/inverters' },
    { item_name: 'Diode', qty_remaining: '200', link: '/diode' },
    { item_name: '100k Resistor', qty_remaining: '1200', link: '/100k-resistor' }
];

export function EachDashboardLiItem({ item }) {
    return (
        <a href={item.link} className="masonry-item">
            <div style={{ fontWeight: 'bold',color:'#3F559F',paddingBottom:10 }}>{item.item_name}</div>
            <div style={{fontSize:14}}>Quantity remaining: {item.qty_remaining}</div>
        </a>
    );
}

export function InventoryLoop() {
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className="masonry-container">
            <div className="masonry-header">Low Inventory</div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
            >
                {data.map((item, index) => (
                    <EachDashboardLiItem key={index} item={item} />
                ))}
            </Masonry>
        </div>
    );
}

export default function DashboardUI() {
    return (
        <div style={{ padding: '20px' }}>
            <InventoryLoop />
        </div>
    );
}
