import * as types from './types';

export function toggleModal(data){
  return{
    type:types.SET_TOGGLE_MODAL,
    data
  }
}



