import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './../../styles.css';
import DashboardUI from '../component/dashboard';
import AddItem from '../component/addItem';
import UpdateItem from '../component/updateItem';

import ProcurementUI from '../component/procurement';
import ProcurementForm from '../component/procurement/form';
import ProcurementListUI from '../component/procurement/list';
import LoginForm from '../component/auth';
import Register from '../component/auth/register';
import { useDispatch, useSelector } from 'react-redux';

import ProtectedRoute from './protectedRoute'; 
import { ActionCreators } from '../actions';

// Sample components for routes
// function Home() { return <h2>Dashboard</h2>; }


function decodeJWT(token) {
  const [header, payload, signature] = token.split('.');

  const base64UrlToBase64 = (base64Url) => {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/');
  };

  const decodeBase64 = (str) => {
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  };

  const decodedPayload = decodeBase64(base64UrlToBase64(payload));
  return JSON.parse(decodedPayload);
}

function About() { return <h2>About Page</h2>; }
function Contact() { return <h2>Contact Page</h2>; }

function ResponsiveWrapper() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);


const signOut = () => {
  localStorage.removeItem('authToken'); // Clear the token from storage
  dispatch(ActionCreators.setUserData({}));
  window.location.href = '/auth'; // Redirect to the login page
}


  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    try {
      const decodedToken = decodeJWT(token);
      // dispatch(ActionCreators.setUserTokenData(token));
      // dispatch(ActionCreators.setUserData(decodedToken));
      return decodedToken;
    } catch (e) {
      console.error('Invalid token:', e);
      return false;
    }
  };


  const setGlobalToken = async () => {

    const token = localStorage.getItem('authToken');
    if (!token) return false;

    try {
      const decodedToken = decodeJWT(token);

      dispatch(ActionCreators.setUserTokenData(token));
      dispatch(ActionCreators.setUserData(decodedToken));

    } catch (e) {
      console.error('Unable to set global token:', e);

    }

  }


 




  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) setDrawerOpen(false);
    };

    setGlobalToken();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>

      <div className="navbar">
        {isMobile && <span className="menu-button" onClick={toggleDrawer}>&#9776;</span>}
        <div className="logo" style={{paddingLeft:!isMobile?250:0,color:'#227823',fontSize:20,fontWeight:'bold'}}>
          <div style={{fontSize:20,display:'block'}}>Powerbox Management Software (PMS)</div>
        </div>
      </div>

      <div className={`drawer ${isMobile && drawerOpen ? 'open' : ''}`}>
        {isMobile && <button className="close-button" onClick={toggleDrawer}>&times;</button>}
        <ul>
          <li></li>
          <li><Link to="/" onClick={toggleDrawer}>Dashboard</Link></li>
          <li><Link to="/add_item" onClick={toggleDrawer}>Add Item</Link></li>
          <li><Link to="/update_item" onClick={toggleDrawer}>Update Item</Link></li>
          <li><Link to="/procurement_manager" onClick={toggleDrawer}>Procurement Manager</Link></li>
          <li><Link to="/contact" onClick={toggleDrawer}>Remove Item</Link></li>
          <li><div to="/contact" onClick={signOut}>Sign Out</div></li>
        </ul>
      </div> 

      <div className="main-content">
        <Routes>
          
          {/* <Route path="/" element={<DashboardUI />} /> */}
          
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
            <Route path="/" element={<DashboardUI />} />
          </Route>

          <Route path="/add_item" element={<AddItem />} />
          <Route path="/update_item" element={<UpdateItem />} />
         
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
            <Route path="/procurement_manager" element={<ProcurementUI />} />
          </Route>
          
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
            <Route path="/procurement_manager/list" element={<ProcurementListUI />} />
          </Route>

          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
            <Route path="/procurement_manager/form" element={<ProcurementForm />} />
          </Route>

          {/* <Route path="/procurement_manager" element={<ProcurementUI />} />
          <Route path="/procurement_manager/list" element={<ProcurementListUI />} />
          <Route path="/procurement_manager/form" element={<ProcurementForm />} /> */}
          
          <Route path="/auth" element={<LoginForm />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>

    </Router>
  );
}

export default ResponsiveWrapper;
