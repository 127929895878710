import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import './style.css'; // Custom styles

const UpdateItem = () => {
  const [formData, setFormData] = useState({
    itemId: 'default-id',
    itemName: 'default-name',
    category: 'Category 1',
    supplier: 'default-supplier',
    reorderLevel: '10',
    description: 'default-description',
    dateAdded: '2023-08-01',
    batchNumber: 'default-batch',
    brand: 'default-brand',
    image: 'default-image-url',
    tags: 'default-tags',
    quantity: '100',
    safetyStockLevel: '20'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const renderField = (name, value) => {
    if (name === 'category') {
      return (
        <div className="form-group" key={name}>
          <label htmlFor={name}>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
          <select id={name} name={name} value={value} onChange={handleChange}>
            <option value="Category 1">Category 1</option>
            <option value="Category 2">Category 2</option>
            <option value="Category 3">Category 3</option>
          </select>
        </div>
      );
    } else if (name === 'description') {
      return (
        <div className="form-group" key={name}>
          <label htmlFor={name}>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
          <textarea
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            rows="3"
          />
        </div>
      );
    } else if (name === 'dateAdded') {
      return (
        <div className="form-group" key={name}>
          <label htmlFor={name}>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
          <input
            type="date"
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
          />
        </div>
      );
    } else {
      return (
        <div className="form-group" key={name}>
          <label htmlFor={name}>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
          <input
            type="text"
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
          />
        </div>
      );
    }
  };

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1
  };

  return (
    <div style={{paddingTop:100,width:'100%'}}>
        <div style={{fontSize:22,color:'#3F559F',paddingBottom:20}}>
            Update Item
        </div>
        
        <form onSubmit={handleSubmit} style={{ margin: 'auto' }}>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {Object.entries(formData).map(([name, value]) => renderField(name, value))}
        </Masonry>


        <div style={{paddingBottom:100,paddingTop:30,display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start',paddingLeft:15}}>
            <button type="submit" style={{ backgroundColor: '#3F559F', color: 'white', border: 'none', cursor: 'pointer',borderRadius:'5px',width:'100px',fontSize:18 }}>
                Update
            </button>
        </div>

        </form>
    </div>
  );
};

export default UpdateItem;
