import React, { useState } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import './reg.css';

function Register() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [token, setToken] = useState('new_user');
  const [permissions, setPermissions] = useState([]);
  const [telegramId, setTelegramId] = useState('');
  const [telegramIdData, setTelegramIdData] = useState([]);
  const [lastTelegramId, setLastTelegramId] = useState("");

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;
    setPermissions((prevPermissions) =>
      checked ? [...prevPermissions, value] : prevPermissions.filter((perm) => perm !== value)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      phoneNumber,
      token,
      telegramId,
      permissions,
      telegramId:String(telegramId),
    };

    console.log(data);

    try {
      const response = await axios.post(
        'https://powerbox-inventory.bitprimesolutions.workers.dev/register',
        JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Registration successful:', response.data);
      alert('User added successfully');
    } catch (error) {
      alert('Unable to add user.');
      console.error('There was an error registering:', error);
    }
  };

  const handleGenerateTelegramId = async () => {
    try {
      const response = await axios.post(
        'https://powerbox-inventory.bitprimesolutions.workers.dev/gettelegram_update',
        JSON.stringify({ phoneNumber }), {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.data.data.result) {
        alert("No data found");
        return;
      }

      let lengthOfData = response.data.data.result.length;
      let arraySpot = lengthOfData - 1;

      setTelegramIdData(response.data.data.result);
      console.log(response.data.data.result);
      console.log("nice one oooo");
      setLastTelegramId(response.data.data.result[arraySpot].update_id);

      alert('Telegram ID generated successfully');


    } catch (error) {
      alert('Unable to generate Telegram ID.');
      console.error('There was an error generating the Telegram ID:', error);
    }
  };


  const handleClearGeneratedTelegramId = async () => {

    const data = {
        lastTelegramId
      };

      try {
        const response = await axios.post(
          'https://powerbox-inventory.bitprimesolutions.workers.dev/cleartelegram_update',
          JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('cleared successfully', response.data);
        alert('cleared successfully');
      }catch (error) {
      alert('Unable to clear.');
      console.error('There was an error generating the Telegram ID:', error);
    }
  };





  const handleCardClick = (updateId) => {
    // console.log('Card clicked:', updateId);
    if(!updateId.message.from.id){
        alert("unable to process event.");
        return
    }

    console.log(updateId.message.from.id);
    setTelegramId(updateId.message.from.id);


  };


  const renderTelegramIdsUI = () => {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1
    };

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {telegramIdData.map((item) => (
          <div key={item.update_id} className="card" onClick={() => handleCardClick(item)}>
            <h3>{item.message.from.username}</h3>
            <p>{item.message.from.id}</p>
          </div>
        ))}
      </Masonry>
    );
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <div className="register-header">
          <h1>Add User</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              required
            />
          </div>

          <div className="form-group" style={{ paddingTop: 10 }}>
            <label htmlFor="telegramId">Telegram ID</label>
            <input
              type="text"
              id="telegramId"
              value={telegramId}
              readOnly
            />
          </div>

          <div className="permission-group">
            <label>Permissions</label>
            <label>
              <input
                type="checkbox"
                value="admin"
                checked={permissions.includes('admin')}
                onChange={handlePermissionChange}
              />
              Admin
            </label>
            <label>
              <input
                type="checkbox"
                value="all"
                checked={permissions.includes('all')}
                onChange={handlePermissionChange}
              />
              All
            </label>
            <label>
              <input
                type="checkbox"
                value="battery_manager"
                checked={permissions.includes('battery_manager')}
                onChange={handlePermissionChange}
              />
              Battery Manager
            </label>
            <label>
              <input
                type="checkbox"
                value="procurement_manager"
                checked={permissions.includes('procurement_manager')}
                onChange={handlePermissionChange}
              />
              Procurement Manager
            </label>
            <label>
              <input
                type="checkbox"
                value="inventory_manager"
                checked={permissions.includes('inventory_manager')}
                onChange={handlePermissionChange}
              />
              Inventory Manager
            </label>
          </div>
          <button type="submit" className="button">Add User</button>

          <div className="form-group" style={{ paddingTop: 30 }}>
            {renderTelegramIdsUI()}
            <button type="button" onClick={handleGenerateTelegramId} className="button" style={{ marginTop: 15, backgroundColor: '#3F559F' }}>
              Generate Telegram ID
            </button>
          </div>

          <div className="form-group" style={{ paddingTop: 0 }}>
            <button type="button" onClick={handleClearGeneratedTelegramId} className="button" style={{ marginTop: 15, backgroundColor: '#A33616' }}>
              Clear Telegram IDs
            </button>
          </div>


        </form>
      </div>
    </div>
  );
}

export default Register;


// import React, { useState } from 'react';
// import axios from 'axios';
// import Masonry from 'react-masonry-css';
// import './reg.css';

// function Register() {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [token, setToken] = useState('new_user');
//   const [permissions, setPermissions] = useState([]);
//   const [telegramId, setTelegramId] = useState('');
//   const [telegramIdData, setTelegramIdData] = useState([]);

//   const handlePermissionChange = (e) => {
//     const { value, checked } = e.target;
//     setPermissions((prevPermissions) =>
//       checked ? [...prevPermissions, value] : prevPermissions.filter((perm) => perm !== value)
//     );
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const data = {
//       phoneNumber,
//       token,
//       permissions,
//       telegramId,
//     };

//     try {
//       const response = await axios.post(
//         'https://powerbox-inventory.bitprimesolutions.workers.dev/register',
//         JSON.stringify(data), {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       console.log('Registration successful:', response.data);
//       alert('User added successfully');
//     } catch (error) {
//       alert('Unable to add user.');
//       console.error('There was an error registering:', error);
//     }
//   };

//   const handleGenerateTelegramId = async () => {
    
//     try {
//       const response = await axios.post(
//         'https://powerbox-inventory.bitprimesolutions.workers.dev/gettelegram_update',
//         JSON.stringify({ phoneNumber }), {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       if(!response.data.data.result){
//         alert("No data found");
//         return
//       }
//       setTelegramIdData(response.data.data.result);
//       console.log(response.data.data.result);
//       alert('Telegram ID generated successfully');
//     } catch (error) {
//       alert('Unable to generate Telegram ID.');
//       console.error('There was an error generating the Telegram ID:', error);
//     }
//   };


//   const updateTId = (tId) => {
//     alert("how will it be done.");
//     console.log(tId);
//   }



// const handleCardClick = (updateId) => {
//     console.log('Card clicked:', updateId);
//   }
  
//   const telegramIdsUI = () => {
//     const breakpointColumnsObj = {
//       default: 3,
//       1100: 2,
//       700: 1
//     };
  
//     return (
//       <Masonry
//         breakpointCols={breakpointColumnsObj}
//         className="my-masonry-grid"
//         columnClassName="my-masonry-grid_column"
//       >
//         {telegramIdData.map((item) => (
//           <div key={item.update_id} className="card" onClick={() => handleCardClick(item)}>
//             <h3>{item.message.from.username}</h3>
//             <p>{item.message.from.id}</p>
//           </div>
//         ))}
//       </Masonry>
//     );
//   }




//   return (
//     <div className="register-container">
//       <div className="register-form">
//         <div className="register-header">
//           <h1>Add User</h1>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <input
//               type="text"
//               id="phoneNumber"
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//               placeholder="Phone Number"
//               required
//             />
//           </div>

//           <div className="form-group" style={{paddingTop:10}}>
//             <label htmlFor="telegramId">Telegram ID</label>
//             <input
//               type="text"
//               id="telegramId"
//               value={telegramId}
//               readOnly
//             />
//           </div>
          
//           <div className="permission-group">
//             <label>Permissions</label>
//             <label>
//               <input
//                 type="checkbox"
//                 value="admin"
//                 checked={permissions.includes('admin')}
//                 onChange={handlePermissionChange}
//               />
//               Admin
//             </label>
//             <label>
//               <input
//                 type="checkbox"
//                 value="all"
//                 checked={permissions.includes('all')}
//                 onChange={handlePermissionChange}
//               />
//               All
//             </label>
//             <label>
//               <input
//                 type="checkbox"
//                 value="battery_manager"
//                 checked={permissions.includes('battery_manager')}
//                 onChange={handlePermissionChange}
//               />
//               Battery Manager
//             </label>
//             <label>
//               <input
//                 type="checkbox"
//                 value="procurement_manager"
//                 checked={permissions.includes('procurement_manager')}
//                 onChange={handlePermissionChange}
//               />
//               Procurement Manager
//             </label>
//             <label>
//               <input
//                 type="checkbox"
//                 value="inventory_manager"
//                 checked={permissions.includes('inventory_manager')}
//                 onChange={handlePermissionChange}
//               />
//               Inventory Manager
//             </label>
//           </div>
//           <button type="submit" className="button">Add User</button>


//           <div className="form-group" style={{paddingTop:30}}>
//             {telegramIdsUI()}
//             <button type="button" onClick={handleGenerateTelegramId} className="button" style={{marginTop:15,backgroundColor:'#3F559F'}}>
//               Generate Telegram ID
//             </button>
//           </div>



//         </form>
//       </div>
//     </div>
//   );
// }

// export default Register;
