import React, { useState } from 'react';
import axios from 'axios';
// import {useJwt} from 'react-jwt';
import './reg.css';
import { useDispatch } from 'react-redux';
import { ActionCreators } from '../../actions';



function decodeJWT(token) {
  const [header, payload, signature] = token.split('.');

  const base64UrlToBase64 = (base64Url) => {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/');
  };

  const decodeBase64 = (str) => {
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  };

  const decodedPayload = decodeBase64(base64UrlToBase64(payload));
  return JSON.parse(decodedPayload);
}


function LoginForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const dispatch = useDispatch();



  const generateToken = async () => {
    // alert("Ha AH");
    // return
    if(!phoneNumber[10]){
      alert("invalid phone number");
      return
    }

    if(phoneNumber[11]){
      alert("invalid phone number");
      return
    }

    try {
      const response = await axios.post('https://powerbox-inventory.bitprimesolutions.workers.dev/generate-token', JSON.stringify({ phoneNumber }));
      alert('Token sent successfully!');
    } catch (error) {
      console.error('Error generating token:', error);
      alert('Failed to send token.');
    }
  };




  const handleLogin = async (e) => {
    e.preventDefault();

    // Clear previous messages
    setError('');
    setSuccess('');

    try {
      const response = await fetch('https://powerbox-inventory.bitprimesolutions.workers.dev/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber,
          token,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Login successful!');
        alert("login suceessfully")
        // Store the JWT in localStorage or sessionStorage
        localStorage.setItem('authToken', data.token);

        // Example usage
        const token = data.token; // Get your JWT from storage
        const payload = decodeJWT(token);

        dispatch(ActionCreators.setUserData(payload));
        window.location.href = '/'; // Redirect to the dashboard

console.log(payload); // Access the decoded payload
        // Handle successful login (e.g., redirect to another page)
      } else {
        setError(data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      phoneNumber,
      token,
    };

    try {
      const response = await axios.post(
        'https://powerbox-inventory.bitprimesolutions.workers.dev/register',
        JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Registration successful:', response.data);
      alert('User added successfully');
    } catch (error) {
      alert('Unable to add user.');
      console.error('There was an error registering:', error);
    }
  };



  return (
    <div className="register-container">
      <div className="register-form">
        <div className="register-header">
          <h1>Sign In</h1>
          <p style={{color:'red'}}>{error}</p>
        </div>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Token</label>
            <input
              type="text"
              id="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="Token"
              required
            />
          </div>

          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center'}}>
              <button type="submit" className="button" style={{marginLeft:10,marginRight:10}}>Sign In</button>

              <div onClick={generateToken} className="button" style={{backgroundColor:'#264856',marginLeft:10,marginRight:10,textAlign:'center'}}>Generate Token</div>
          </div>
       
          

        </form>
      </div>
    </div>
  );
}

export default LoginForm;