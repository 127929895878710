import * as types from './types';

export function setProcureModalData(data){
  return{
    type:types.SET_PROCURE_MODAL_DATA,
    data
  }
}


export function setUserData(data){
  return{
    type:types.SET_USER_DATA,
    data
  }
}

export function setUserTokenData(data){
  return{
    type:types.SET_USER_TOKEN_DATA,
    data
  }
}



