import React from 'react';

const DateFormatter = ({ isoDate }) => {
  // Convert to a JavaScript Date object
  const date = new Date(isoDate);

  // Extract the day, month, and year
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
  const year = date.getUTCFullYear();

  // Format the date as a string (e.g., "10-08-2024")
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

  return <span>{formattedDate}</span>;
};

export default DateFormatter;
